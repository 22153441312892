import { makeStyles } from  "@material-ui/core";

export const useStyles = makeStyles(() => ({
  buttonWithChildren: {
    pr: '8px',
    py: '12px',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    justifyContent: 'flex-start'
  },
  listItemWithChildren: {
    display: 'block',
    py: 0
  },
  buttonItem: {
    justifyContent: 'flex-start',
    textAlign: 'left',
    paddingLeft: '25px',
    paddingRight: '8px',
    textTransform: 'none',
    width: '100%'
  },
  boxTitleContain: {
    flexGrow: 1,
    marginLeft: '20px'
  },
  listSubheader: {
    fontSize: '0.75rem',
    lineHeight: 2.5,
    fontWeight: 700,
    textTransform: 'uppercase'
  }
}));
