import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import decode from "jwt-decode";
import * as api from "../api/auth";

const initialState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    isInitialized: true,
    user: null,
  }),
};

const reducer = (state = initialState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  let accessToken;
  let userInfo;

  if (localStorage.getItem("profile")) {
    accessToken = JSON.parse(localStorage.getItem("profile"));
  }

  let decodedToken;

  useEffect(() => {
    if (accessToken) {
      decodedToken = decode(accessToken);
      if (decodedToken) {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: true,
            isInitialized: true,
            user: {
              id: userInfo?.id,
              name: userInfo?.name,
              email: userInfo?.email,
            },
          },
        });
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            isInitialized: true,
            user: null,
          },
        });
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (decodedToken !== undefined) {
      if (decodedToken.exp * 1000 < new Date().getTime()) {
        logout();
      }
    }
  }, []);

  const login = async (values) => {
    let accessToken;
    try {
      const response = await api.login(values);
      accessToken = response?.data.token;
      const user = response?.data?.payload;
      localStorage.setItem("userInfo", JSON.stringify(user));
      localStorage.setItem("profile", JSON.stringify(accessToken));
      if (accessToken) {
        dispatch({
          type: "LOGIN",
          payload: {
            isAuthenticated: true,
            user: {
              name: `${user?.nombre} ${user?.apellido}`,
              username: user?.username,
            },
          },
        });
      } else {
        throw new Error("Contraseña Incorrecta");
      }
    } catch (err) {
      console.error(err);
      accessToken = null;
    }
    return accessToken;
  };

  const logout = async () => {
    localStorage.removeItem("profile");
    localStorage.removeItem("accessToken");
    dispatch({
      type: "LOGOUT",
      payload: {
        isAuthenticated: false,
        isInitialised: true,
        user: null,
      },
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
