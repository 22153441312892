import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Drawer } from "@material-ui/core";
import NavSection from "./navigation/NavSection";
import Scrollbar from "./Scrollbar";
import GroupIcon from "@material-ui/icons/Group";
import AddBusinessIcon from "@material-ui/icons/AddBusiness";
import SecurityIcon from '@mui/icons-material/Security';

const sections = [
  {
    items: [
      {
        id: 0,
        title: "Usuarios",
        path: "/dashboard",
        icon: <GroupIcon fontSize="small" />,
      },
      {
        id: 1,
        title: "Comercios",
        path: "/dashboard/shops",
        icon: <AddBusinessIcon fontSize="small" />,
      },
      {
        id: 2,
        title: "Vip",
        path: "/dashboard/vips",
        icon: <SecurityIcon fontSize="small" />,
      },
    ],
  },
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        backgroundColor: "#000",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ pt: 4, pl: 4, pb: 4 }}>
          <img src="/underc0de-logo.png" alt="Underc0de" />
        </Box>
        <Box
          sx={{
            p: 2,
            padding: "10px",
            marginTop: "23px",
          }}
        >
          {sections.map((section, index) => (
            <NavSection
              key={index}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (!openMobile) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "#000",
            height: "100vh",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "#000",
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
