import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Avatar,
  Box,
  ButtonBase,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import useAuth from "../hooks/useAuth";

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate("/");
    } catch (err) {
      console.error(err);
      toast.error("Error al finalizar sesión.");
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          marginRight: 2,
        }}
      >
        <Avatar
          src={"/underc0de-header-logo.png"}
          sx={{
            height: 50,
            width: 50,
          }}
          style={{
            borderRadius: 16,
            marginRight: 11,
          }}
        />
        <Box
          sx={{
            alignSelf: "self-end",
            marginBottom: "3%",
            "& svg": {
              color: "typography.black",
            },
          }}
        ></Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 220,
            borderRadius: "4px",
            marginTop: 1,
            marginLeft: -2.5,
            boxShadow: 4,
          },
        }}
      >
        <Box sx={{ mt: 1, mb: 1 }}>
          <MenuItem
            onClick={handleLogout}
            style={{
              width: "106%",
            }}
          >
            <ListItemText
              primary={
                <Typography
                  color="#3D3D3D"
                  variant="subtitle2"
                  fontFamily="Helvetica"
                  fontWeight="bold"
                  fontSize={11}
                >
                  Cerrar sesión
                </Typography>
              }
            />
          </MenuItem>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
