import axios from "axios";
const { REACT_APP_API } = process.env;

const getAxiosInstance = () => {
  return axios.create({
    baseURL: REACT_APP_API,
    headers: {
      authorization: localStorage.getItem("profile")
        ? JSON.parse(localStorage.getItem("profile"))
        : null,
    },
  });
};

export default getAxiosInstance;
