import { Container, Grid, TextField, Button } from "@material-ui/core";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import useAuth from "../../../hooks/useAuth";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const userInfo = {
    user: "",
    password: "",
  };

  return (
    <Container
      maxWidth
      sx={{
        backgroundImage: "url(https://i.imgur.com/yAdJWiO.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
      }}
    >
      <Grid
        container
        justifyContent="center"
        position="absolute"
        marginTop="10%"
        marginLeft="-2%"
      >
        <Formik
          initialValues={userInfo}
          validationSchema={Yup.object().shape({
            user: Yup.string().required("Campo requerido"),
            password: Yup.string()
              .required("Campo requerido")
              .max(8, "Debe contener 8 caracteres"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const response = await login(values);
              if (response) {
                setStatus({ success: true });
                setSubmitting(false);
                navigate("/dashboard");
              } else {
                setStatus({ success: false });
                setErrors({
                  password: "Contraseña Incorrecta",
                });
              }
            } catch (err) {
              setStatus({ success: false });
              setErrors({ errors: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            values,
            handleSubmit,
            handleChange,
            validateForm,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid item p={6}>
                <Grid
                  display="flex"
                  flexDirection={"column"}
                  sx={{ backgroundColor: "#FFF" }}
                  p={6}
                >
                  <TextField
                    variant="outlined"
                    name="user"
                    label="Usuario"
                    size="small"
                    onChange={handleChange}
                    value={values?.user || ""}
                    helperText={errors?.user}
                    error={errors?.user}
                  />
                  <TextField
                    error={errors?.password}
                    type="password"
                    variant="outlined"
                    label="Contraseña"
                    sx={{ marginTop: "8%", marginBottom: "8%" }}
                    size="small"
                    name="password"
                    onChange={handleChange}
                    value={values?.password || ""}
                    helperText={errors?.password}
                  />
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    onClick={() => validateForm()}
                  >
                    Iniciar Sesión
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Container>
  );
};
export default Login;
