import { Suspense, lazy } from "react";
import LoadingScreen from "./components/LoadingScreen";
import AuthGuard from "./components/AuthGuard";
import GuestGuard from "./components/GuestGuard";
import DashboardLayout from "./components/DashboardLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loadable(lazy(() => import("./pages/auth/login/Login")));
const Home = Loadable(lazy(() => import("./pages/home/Home")));
const Shops = Loadable(lazy(() => import("./pages/shops/Shops")));
const Vips = Loadable(lazy(() => import("./pages/vips/Vips")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "shops",
        element: <Shops />,
      },
      {
        path: "vips",
        element: <Vips />,
      },
    ],
  },
  {
    path: "",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "shops",
        element: <Shops />,
      },
    ],
  },
];

export default routes;
