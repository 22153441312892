import getAxiosInstance from "./index";

const API = getAxiosInstance();

export const login = async (values) => {
  const obj = {
    username: values.user,
    password: values.password
  }
  const response = await API.post(`/api/admin/login`, obj);
  return response.data;
};
