import { useEffect } from "react";
import { CssBaseline } from '@material-ui/core';
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import Login from "./pages/auth/login/Login";
import routes from "./routes";

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (!localStorage.getItem("profile")) {
        auth.logout();
      }
    }
  }, [content]);

  return (
    <>
      <CssBaseline />
      <Toaster position="top-right" />
      {auth.isAuthenticated ? content : <Login />}
    </>
  );
};

export default App;
